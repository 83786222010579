@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'TTNormsProRegular';
    src: url(../public/fonts/TTNormsPro-Regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'TTNormsProMedium';
    src: url(../public/fonts/TTNormsPro-Medium.ttf) format('truetype');
  }
  @font-face {
    font-family: 'TTNormsProBold';
    src: url(../public/fonts/TTNormsPro-Bold.ttf) format('truetype');
  }

}

html {
  scroll-behavior: smooth;
}

.scrollbar-hide {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 11 */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

body{
  margin: 0;
  padding: 0;
  background: #EBEBEB;
}

body::-webkit-scrollbar {
  display: none;
}

.swiper-pagination{
  position:relative !important;
  margin-top: 30px;
}

.swiper-pagination-bullet{
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.swiper-pagination-bullet-active{
  background: #7B7B7B !important;
}

.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizont{
  width: 100% !important;
}

.hamburger{
  padding: 16px 12.5px;
  background: white;
  border-radius: 100px;
}

.hamburger .line{
  width: 19px;
  height: 2px;
  background-color: #0110CF;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:first-child{
  margin-bottom: 8px;
}

.hamburger:hover{
  cursor: pointer;
}

#hamburger.is-active{
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #EBEBEB;
}

#hamburger.is-active .line:nth-child(1),
#hamburger.is-active .line:nth-child(2){
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

#hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(4.5px);
  -ms-transform: translateY(4.5px);
  -o-transform: translateY(4.5px);
  transform: translateY(4.5px);
}

#hamburger.is-active .line:nth-child(2){
  -webkit-transform: translateY(-6px) rotate(90deg);
  -ms-transform: translateY(-6px) rotate(90deg);
  -o-transform: translateY(-6px) rotate(90deg);
  transform: translateY(-6px) rotate(90deg);
}


@media (min-width: 1535px) and (max-width: 1830px ){
  .text-smaller {
    font-size: 50px !important;
  }
}